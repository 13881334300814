/* --------------------------------------------------------------------------------------
  scan.service.js
  Copyright © 2021 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class scanService {
  /* @ngInject*/
  constructor(
    xeroxEIPParameters,
    languagesService,
    deviceInfoService,
    apiClientService,
    workflowScanningService,
    sessionDataService,
    templateService,
    $translate
  ) {
    this.xeroxEIPParameters = xeroxEIPParameters;
    this.languagesService = languagesService;
    this.apiClientService = apiClientService;
    this.deviceInfoService = deviceInfoService;
    this.workflowScanningService = workflowScanningService;
    this.sessionDataService = sessionDataService;
    this.templateService = templateService;
    this.$translate = $translate;
  }

  async setOptions(isAlta) {
    this.twoSidedScanOptions = this.xeroxEIPParameters.sides.filter((side) =>
      side.scope.includes("scan") &&
      side.device.includes(isAlta ? "Alta" : "Versa")
    );
    this.originalOrientationOptions = this.xeroxEIPParameters.orientations;
    this.originalTypeOptions = this.xeroxEIPParameters.types;

    this.originalSizeOptions = isAlta
    ? this.xeroxEIPParameters.sizes
    : this.xeroxEIPParameters.sizes.filter((s) => !s.a3);


    const deviceCapabilities = await this.deviceInfoService.getDeviceCapabilities();
    const outputColorOptionsLoader = this.xeroxEIPParameters.colorModes;
    
    this.outputColorOptions = deviceCapabilities.colorSupport
      ? outputColorOptionsLoader
      : outputColorOptionsLoader.filter((color) => color.key !== "Fullcolor");

    this.resolutionOptions = this.xeroxEIPParameters.resolutions;
    await this.setDefaults();
  }

  async setDefaults() {
    this.twoSidedScan = this.twoSidedScanOptions[0].key;
    this.twoSidedScanImage = this.twoSidedScanOptions[0].img;
    this.twoSidedScanText = await this.$translate(
      this.twoSidedScanOptions[0].translationKey
    );
    let defaultOriginalOrientation = this.originalOrientationOptions.find(o=>o.key=="PORTRAIT");
    if(!defaultOriginalOrientation){
      defaultOriginalOrientation = this.originalOrientationOptions[0];
    }
    this.originalOrientation = defaultOriginalOrientation.key;
    this.originalOrientationImage = defaultOriginalOrientation.img;
    this.originalOrientationText = await this.$translate(
      defaultOriginalOrientation.translationKey
    );
    this.originalType =  this.originalTypeOptions[0].key;
    this.originalTypeImage = this.originalTypeOptions[0].img;
    this.originalTypeText = await this.$translate(
      this.originalTypeOptions[0].translationKey
    );

    this.originalSize = this.originalSizeOptions[0].key;
    this.originalSizeText = await this.$translate(
      this.originalSizeOptions[0].value
    );
    this.originalSizeOrientation = null
    

    this.resolution = this.resolutionOptions[4].key;
    this.resolutionText = this.resolutionOptions[4].value;

    this.outputColor = this.outputColorOptions[0].key;
    this.outputColorText = await this.$translate(
      this.outputColorOptions[0].translationKey
    );

    this.copies = 1;
  }

  async setDefaultLanguage() {
    if (!this.languages) this.languages = this.languagesService.getLanguages();
    this.language = this.languages[4];
    this.searchable = true;
  }

  async scan(scanProfileId, data) {
    try {
      const scanTemplate = await this.apiClientService.post(
        `/v1/scan/ticket`,
        data
      );
      const count = (scanTemplate.match(/enum_filingprotocol/g) || []).length;

      this.scanCheckSum = await this.templateService.put(
        this.sessionDataService.deviceUrl,
        `xerox_swp_${scanProfileId}.xst`,
        scanTemplate
      );

      const scanJobId = await this.workflowScanningService.submitScanTemplate(
        this.sessionDataService.deviceUrl,
        `xerox_swp_${scanProfileId}.xst`,
        "false",
        null
      );

      return {
        scanJobId,
        jobType: count > 1 ? "WorkflowJob" : "WorkflowScanning",
      };
    } catch (error) {
      throw error;
    }
  }

  async getUploadStatus(scanProfileId) {
    try {
      const fn = () =>
        this.apiClientService.get(`/scan/complete/${scanProfileId}`);
      const fnCondition = (result) => result !== true;
      const scanStatus = await this.poll(fn, fnCondition, 1000);
      return scanStatus;
    } catch (error) {
      throw error;
    }
  }

  async getAllTemplates() {
    try {
      return this.templateService.getAllTemplates(
        this.sessionDataService.deviceUrl
      );
    } catch (error) {
      throw error;
    }
  }

  async getDocumentSendStatus(scanProfileId) {
    try {
      return this.apiClientService.get(`/v1/status?token=${scanProfileId}`);
    } catch (error) {
      throw error;
    }
  }

  delete(scanProfileId) {
    try {
      return this.templateService.delete(
        this.sessionDataService.deviceUrl,
        `xerox_swp_${scanProfileId}.xst`,
        this.scanCheckSum
      );
    } catch (error) {
      throw error;
    }
  }

  deleteTemplateByName(templateName, scanCheckSum) {
    try {
      return this.templateService.delete(
        this.sessionDataService.deviceUrl,
        templateName,
        scanCheckSum
      );
    } catch (error) {
      throw error;
    }
  }

  async poll(fn, fnCondition, ms) {
    let result = await fn();
    while (fnCondition(result)) {
      await this.wait(ms);
      result = await fn();
    }
    return result;
  }

  wait(ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}

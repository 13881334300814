/* --------------------------------------------------------------------------------------
   common.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class commonService {
  /*@ngInject*/
  constructor (appSettings, persistService, deviceInfoService, $translate, $timeout, $window, $state, $filter) {
    this.appSettings = appSettings
    this.persistService = persistService
    this.deviceInfoService = deviceInfoService
    this.$translate = $translate
    this.$timeout = $timeout
    this.$window = $window
    this.$state = $state
    this.$filter = $filter
  }
  validateDate(date) {
    if (date == null || date.length < 1)
      return false
    return true
  }
  updateView() {
    return this.$timeout(() => 1 == 1)
  }
  getNewGuid() {
  //// TEMP: when scan profile available this will not be needed
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }
  getScreenSize() {
    return (angular.element(this.$window).width() > 801)? 10 : 7
  }
  getDeviceLocation() {
    const location = this.persistService.getObject('location')
    const defaultLocation = {code:'us',name:'United States'}
    if (!location)
      return defaultLocation
    return location
  }
  updateLocation(location) {
    if (location && location.code && location.name) {
      this.persistService.setObject('location', location)
    }
  }
  getCurrentSlot() {
    const host = this.$window.location.host
    console.log('host is: ' + host)

    if (host.includes('localhost'))
      return 'local'
    if (host.includes('-dev'))
      return 'dev'
    if (host.includes('-demo'))
      return 'demo'
    if (host.includes('-test'))
      return 'test'
    if (host.includes('-staging'))
      return 'staging'
  }
  async isAltalink() {
    const deviceInfo = await this.deviceInfoService.get()
    return deviceInfo.model.toLowerCase().includes('alta')
  }
  getCurrencyFormat(number) {
    return this.$filter('currency')(number, '')
  }
  async getStylesheetSetType() {
    const deviceInfo = await this.deviceInfoService.get()

    if (deviceInfo.styleGeneration.includes('8.')) 
      return '8thgen'
    const screenSize = this.getScreenSize()
    if (screenSize == 7) {
      return '800'
    } else {
      return '1024'
    } 
  }
  exitApp() {
    try {
      ExitCUIMode()
    } catch {
      console.log("Exit CUI embedded function not found, switching to empty state instead")
      this.$state.go('empty')
    }
  }
  closeEmbeddedKeyboard() {
    try {
      EIP_CloseEmbeddedKeyboard()
    } catch (error) {
      console.log("Close Embedded Keyboard embedded function not found, ignoring")
    }
  }
}

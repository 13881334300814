/* --------------------------------------------------------------------------------------
   presets.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const presetsComponent = {
  template: require("./presets.html"),
  bindings: {
    display: "=",
  },
  controllerAs: "vm",
  controller: class presetsController {
    /* @ngInject */
    constructor(
      commonService,
      scanPrintService,
      destinationsService,
      $state,
      $translate
    ) {
      this.commonService = commonService;
      this.scanPrintService = scanPrintService;
      this.destinationsService = destinationsService;
      this.$state = $state;
      this.$translate = $translate;
    }
    async $onInit() {
      this.loading = true;
      this.commonService.updateView();

      this.screenSize = this.commonService.getScreenSize();
      this.boundingBoxHeight = this.screenSize === 7 ? 370 : 472;
      this.boundingBoxWidth = this.screenSize === 7 ? 278 : 350;
      this.refreshBoundingBox();
      this.display = true;
      this.commonService.updateView();
      this.presets = await this.scanPrintService.getPresets();
      this.presetListHeight = this.getPresetListHeight(this.presets.length);
      this.updatePresetsTableScroll();
      this.commonService.updateView();

      this.deleteAlertButtons = [
        {
          alertIcon: "glyphicon-close",
          text: await this.$translate("CANCEL"),
          class: "forest xglicon reverted",
          click: () => this.cancelDelete(),
        },
        {
          alertIcon: "glyphicon-trash",
          text: await this.$translate("DELETE"),
          class: "forest xglicon reverted",
          click: async () => await this.deletePreset(),
        },
      ];

      this.loading = false;
      this.commonService.updateView();
    }

    ok() {
      if (!this.preset) {
        angular.element("#select-preset-banner").xrxbanner("call");
      } else {
        // destroy scroll before move to other screen
        if (angular.element("#preset-description-box-scroll")) {
          angular
            .element("#preset-description-box-scroll")
            .xrxscrollable("destroy");
        }
        angular.element("#preset-list-wrapper").xrxscrollable("destroy");
        this.destinationsService.presetBackup = this.preset.preset;
        this.destinationsService.presetBackupName = this.preset.name;
        this.destinationsService.presetBackupIsShared = this.preset.isShared;
        this.destinationsService.isPresetChanged = false;
        this.destinationsService.isPrintSettingsChanged = false;
        this.$state.go("destinations");
      }
    }
    cancel() {
      // destroy scroll before move to other screen
      if (angular.element("#preset-description-box-scroll")) {
        angular
          .element("#preset-description-box-scroll")
          .xrxscrollable("destroy");
      }
      angular.element("#preset-list-wrapper").xrxscrollable("destroy");

      this.$state.go("main");
    }
    getPresetListHeight(rows) {
      const calcHeight = 72 * rows,
        maxHeight = this.screenSize === 7 ? 388 : 510;

      return calcHeight < maxHeight ? calcHeight : maxHeight;
    }
    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#preset-description-box-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth,
        });

        if (
          angular
            .element("#preset-description-box-scroll .xrx-bounding-box-content")
            .height() < this.boundingBoxHeight
        ) {
          angular
            .element("#preset-description-box-scroll .shadow")
            .addClass("hide");
        } else {
          angular
            .element("#preset-description-box-scroll .shadow")
            .removeClass("hide");
        }
      });
    }
    updatePresetsTableScroll() {
      setTimeout(() => {
        angular.element("#preset-list-wrapper").xrxscrollable({ scroll: true });
        if (this.hasScroll()) {
          angular.element("#preset-list-wrapper .shadow").removeClass("hide");
        } else {
          angular.element("#preset-list-wrapper .shadow").addClass("hide");
        }
        this.commonService.updateView();
      }, 100);
    }

    hasScroll() {
      return this.screenSize === 7
        ? this.presets.length * 69 > 390
        : this.presets.length * 63 > 500;
    }
    selectPreset(preset) {
      const newPresetObject = JSON.stringify({
        name: preset.name,
        isShared: preset.isShared,
      });
      const oldPresetObject = this.preset
        ? JSON.stringify({
            name: this.preset.name,
            isShared: this.preset.isShared,
          })
        : "";

      if (newPresetObject === oldPresetObject) {
        this.preset = null;
      } else {
        this.preset = angular.copy(preset);
      }
      this.commonService.updateView();
      this.refreshBoundingBox();
    }
    deletePresetConfirm(preset) {
      this.presetToDelete = preset;
      this.commonService.updateView();
    }
    cancelDelete() {
      this.presetToDelete = null;
      this.commonService.updateView();
    }
    async deletePreset() {
      try {
        await this.scanPrintService.deletePreset(
          this.presetToDelete.name,
          this.presetToDelete.isShared
        );
        this.presets = this.presets.filter(
          (preset) => preset.name !== this.presetToDelete.name
        );
        this.presetListHeight = this.getPresetListHeight(this.presets.length);
        this.presetToDelete = null;
        this.preset = null;
        this.commonService.updateView();
      } catch (error) {
        this.presetToDelete = null;
        this.preset = null;
        this.commonService.updateView();
        this.updatePresetsTableScroll();
      } finally {
        if (this.presets.length === 0) {
          // destroy scroll before move to other screen
          if (angular.element("#preset-description-box-scroll")) {
            angular
              .element("#preset-description-box-scroll")
              .xrxscrollable("destroy");
          }
          angular.element("#preset-list-wrapper").xrxscrollable("destroy");

          this.$state.go("destinations");
        }
      }
    }
  },
};

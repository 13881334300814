/* --------------------------------------------------------------------------------------
   addRecipientPopup.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const addRecipientPopupComponent = {
    template: require('./addRecipientPopup.html'),
    bindings: {
        display: '=',
        ok: '&',
        cancel: '&'
    },
    controllerAs: 'vm',
    controller: class addRecipientPopupController {
    /* @ngInject */
        constructor(commonService, destinationsService) {
            this.commonService = commonService
            this.destinationsService = destinationsService
        }

        $onInit() {
            this.screenSize = this.commonService.getScreenSize()
            this.resetForm()
        }

        resetForm() {
            this.disableOk = true
            this.editing = false
            this.emailRecipient = ''
            this.emailRecipientRequired = false
            this.emailRecipientInvalid = false
            this.commonService.updateView()
        }

        onOk() {
            if (!this.emailRecipient) {
                this.emailRecipientRequired = true
                this.commonService.updateView()
                this.showEnterEmailAddressBanner()
                return
            }
            if (!this.validateEmail(this.emailRecipient)) {
                this.emailRecipientInvalid = true
                this.commonService.updateView()
                return
            }
            if (this.destinationsService.recipientsBackup.findIndex((recipient) => recipient === this.emailRecipient) >= 0) {
                this.showEmailAlreadyExistsBanner()
                return
            }
            document.activeElement.blur()
            this.destinationsService.recipientsBackup = [ this.emailRecipient, ...this.destinationsService.recipientsBackup ]
            this.emailRecipient = ''
            this.disableOk = true
            this.commonService.updateView()
            this.updateEmailRecipientsPopupTableScroll()
            this.ok()
        }

        onCancel() {
            this.resetForm()
            this.cancel()
        }

        showEnterEmailAddressBanner() {
            angular.element('#enter-email-address-banner').xrxbanner('call')
        }

        showEmailAlreadyExistsBanner() {
            angular.element('#email-already-exists-banner').xrxbanner('call')
        }

        emailRecipientClick() {
            this.editing = true
            this.commonService.updateView()
        }

        emailRecipientClose() {
            this.editing = false
            this.commonService.updateView()
        }

        emailRecipientChanged() {
            this.emailRecipientRequired = false
            this.emailRecipientInvalid = false
            this.emailRecipientBackup = angular.copy(this.emailRecipient)
            if (this.emailRecipient === '') {
                this.emailRecipientRequired = true
            }
            if (this.emailRecipientRequired || this.emailRecipientInvalid) {
                this.disableOk = true
            } else {
                this.disableOk = false
            }
            this.commonService.updateView()
        }

        validateEmail(email) {
            const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

            return re.test(email)
        }

        emailRecipientCanceled() {
            this.emailRecipient = this.emailRecipientBackup
            this.commonService.updateView()
        }

        onBeforeClosePopup() {
            this.emailRecipient = null
            this.emailRecipientBackup = null
            this.emailRecipientRequired = false
        }

        updateEmailRecipientsPopupTableScroll() {
            setTimeout(() => {
                angular.element('#recipients-wrapper').xrxscrollable({ scroll: true })
                if (this.hasScroll()) {
                    angular.element('#recipients-table-wrapper').removeClass('no-scroll');
                    angular.element('#recipients-wrapper').xrxscrollable({ scroll: true });
                    angular.element('#recipients-wrapper .shadow').removeClass('hide');
                } else {
                    angular.element('#recipients-table-wrapper').addClass('no-scroll');
                    angular.element('#recipients-wrapper').xrxscrollable('destroy');
                    angular.element('#recipients-wrapper .shadow').addClass('hide');
                }
                this.commonService.updateView()
            }, 100)
        }

        hasScroll() {
            return this.screenSize === 7 ? this.destinationsService.recipientsBackup.length * 69 > 308 : this.destinationsService.recipientsBackup.length * 63 > 420
        }
    }
}

/* --------------------------------------------------------------------------------------
   xwKeyboard.directive.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/* eslint semi: ["error", "never"]*/
export class xwKeyboard {
    /* @ngInject*/
    constructor(appSettings) {
        this.restrict = 'A'
        this.scope = {
            onVisible: '&',
            onChange: '&',
            onAccepted: '&',
            autoAccept: '<',
            stayOpen: '<',
            alwaysOpen: '<',
            maxLength: '<',
            noContainer: '<',
            onCancel: '&',
            beforeClose: '&',
            beforeVisible: '&',
            onHide: '&',
            ngModel: '=',
            backgroundSlide: '<'
        }
        this.appSettings = appSettings
    }
    link(scope, elem, attrs) {
        let options = {
            layout: attrs.layout,
            theme: attrs.theme ? attrs.theme : this.appSettings.primaryColor,
            restrictInput: attrs.restrictInput,
            preventPaste: attrs.preventPaste,
            initialPlaceholder: attrs.initialPlaceholder,
            maxlength: scope.maxLength,
            autoAccept: scope.autoAccept,
            backgroundSlide: scope.backgroundSlide,
            appendLocally: attrs.appendTo ? true: false,
            appendTo: attrs.appendTo,
            stayOpen: scope.stayOpen,
            alwaysOpen: scope.alwaysOpen,
            visible: () => this.visible(scope, attrs.layout),
            canceled: () => scope.onCancel(),
            change: () => this.change(scope, elem),
            beforeClose: () => scope.beforeClose(),
            beforeVisible: () => (scope.noContainer && this.removeContainer()) && scope.beforeVisible(),
            accepted: (ev, kbd, e) => scope.onAccepted(),
            hidden: () => scope.onHide()
        }
        attrs.layout == 'xrxNum' && console.log(options)

        elem.first().xrxkeyboard(options)

        scope.$on('$destroy', () => {
            elem.first().xrxkeyboard('destroy').remove()
        })

        elem.bind('keydown keypress', (ev) => {
            if (ev.which === 13) {
                elem.first().getkeyboard().accept()
            }
        })
    }
    visible(scope, layout) {
        if (layout && layout.includes('xrxNum')) {
            const okButtonContent = angular.element("span.ui-keyboard-text:contains(' OK')")
            okButtonContent.attr('class', 'xrx-button-text')
            const clearButtonContent = angular.element(".ui-keyboard-clear span")
            clearButtonContent.html("&#xE0F3;")
        }
        scope.onVisible()
    }
    change(scope, elem) {
        scope.ngModel = elem.first().getkeyboard().$preview.val()
        scope.$apply()
        scope.onChange()        
    }
    removeContainer() {
        angular.element(".xrx-keyboard").css("position", "relative");
        angular.element(".xrx-num-accept").css("visibility", "hidden");
        angular.element(".xrx-num-cancel").css("visibility", "hidden");
        angular.element(".xrx-keyboard").css("box-shadow", "none");
        angular.element(".xrx-keyboard").css("border-top", "none");
        angular.element(".xrx-keyboard").css("transition", "width 0s");
        angular.element(".xrx-keyboard").css("padding-top", "0");
        angular.element(".xrx-keyboard").css("padding-bottom", "0");
    }
    static directiveFactory(appSettings) {
        xwKeyboard.instance = new xwKeyboard(appSettings)
        return xwKeyboard.instance
    }
}

/* --------------------------------------------------------------------------------------
   main.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const mainComponent = {
  template: require("./main.html"),
  controllerAs: "vm",
  controller: class mainCtrl {
    /*@ngInject*/
    constructor(
      appSettings,
      commonService,
      scanPrintService,
      scanService,
      destinationsService,
      errorService,
      webServiceStatusService,
      privacyStatementLanguageService,
      deviceInfoService,
      $state,
      $translate,
      moment
    ) {
      this.appSettings = appSettings;
      this.commonService = commonService;
      this.scanPrintService = scanPrintService;
      this.scanService = scanService;
      this.destinationsService = destinationsService;
      this.errorService = errorService;
      this.webServiceStatusService = webServiceStatusService;
      this.privacyStatementLanguageService = privacyStatementLanguageService;
      this.deviceInfoService = deviceInfoService;
      this.$state = $state;
      this.$translate = $translate;
      this.moment = moment;
    }

    async $onInit() {
      this.loading = true;

      await this.webServiceStatusService
        .getStatus()
        .then((res) => {
          if (res.some((s) => !s)) {
            const keys = [];
            if (!res[0]) {
              keys.push("DEVICE_CONFIGURATION");
            }
            if (!res[1]) {
              keys.push("JOB_MANAGEMENT_EXTENSION");
            }
            if (!res[2]) {
              keys.push("SCAN_TEMPLATE_MANAGEMENT");
            }
            if (!res[3]) {
              keys.push("SCAN_EXTENSION");
            }
            keys.push("CONTACT_ADMINISTRATOR_TO_ENABLE2");
            this.errorType = {
              titleKey: "EIP_SERVICES",
              keys: keys,
              exitApp: true,
            };

            this.onErrorClose = this.errorService.getErrorCallback(
              this.errorType
            );
          }
        })
        .catch((err) => {});

      angular.element(".ui-keyboard").remove();
      this.loading = false;
      this.commonService.updateView();

      // Deleting Existing Templates Starting with xerox_swp_
      const templateList = await this.scanService.getAllTemplates();
      const deleteTemplatesPromises = Object.keys(templateList)
        .filter((itemKey) => itemKey.startsWith("xerox_swp_"))
        .map((key) => {
          return this.scanService.deleteTemplateByName(key, templateList[key]);
        });
      await Promise.all(deleteTemplatesPromises);

      if (this.scanPrintService.presets.length === 0) {
        this.$state.go("destinations");
      }
    }

    onErrorClose() {
      this.errorType = null;
      this.commonService.updateView();
    }

    openPrivacyStatementPopup() {
      document.activeElement.blur();
      this.privacyStatement = true;
      this.commonService.updateView();
    }

    privacyStatementClosed() {
      document.activeElement.blur();
      this.privacyStatement = false;
      this.commonService.updateView();
    }
  },
};

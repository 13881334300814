/* --------------------------------------------------------------------------------------
   savePresetPopup.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const savePresetPopupComponent = {
  template: require("./savePresetPopup.html"),
  bindings: {
    display: "=",
    ok: "&",
    cancel: "&",
  },
  controllerAs: "vm",
  controller: class savePresetPopupController {
    /* @ngInject */
    constructor(
      commonService,
      destinationsService,
      scanPrintService,
      errorService,
      $translate,
    ) {
      this.commonService = commonService;
      this.destinationsService = destinationsService;
      this.scanPrintService = scanPrintService;
      this.errorService = errorService;
      this.$translate = $translate;
    }

    async $onInit() {
      this.screenSize = this.commonService.getScreenSize();
      this.resetForm();
      this.sharePresetType = true;
      this.presetName = "";
      this.errorType = false;
      this.showOverwritePresetAlert = false;
      this.overwriteAlertButtons = [
        {
          alertIcon: "glyphicon-close",
          text: await this.$translate("CANCEL"),
          class: "forest xglicon reverted full-width",
          click: () => this.cancelOverwrite(),
        },
        {
          alertIcon: "glyphicon-download_square",
          text: await this.$translate("SAVE_AS_NEW_PRESET"),
          class: "forest xglicon reverted full-width",
          click: () => this.saveAsNewPreset(),
        },
        {
          alertIcon: "glyphicon-overwrite_square",
          text: await this.$translate("OVERWRITE"),
          class: "forest xglicon reverted full-width",
          click: () => this.overwrite(),
        },
      ];
    }

    cancelOverwrite() {
      this.showOverwritePresetAlert = false;
      this.commonService.updateView();
    }

    saveAsNewPreset() {
      this.showOverwritePresetAlert = false;
      this.commonService.updateView();
    }

    async overwrite() {
      try {
        await this.save();
      } catch (error) {
        this.errorType = error.titleKey
          ? error
          : this.errorService.getErrorByStatusCode(error.status);
        this.errorCallback = this.errorService.getErrorCallback(this.errorType);

        document.activeElement.blur();
        this.presetName = "";
        this.disableOk = true;
        this.commonService.updateView();
        await this.scanPrintService.getPresets();
        this.ok();
      }
    }

    resetForm() {
      this.sharePresetType = true;
      this.sharePreset = true;
      this.presetName = "";
      this.disableOk = true;
      this.editing = false;
      this.presetNameRequired = false;
      this.presetNameInvalid = false;
      this.commonService.updateView();
    }

    async onOk() {
      try {
        if (!this.presetName) {
          this.presetNameRequired = true;
          this.showEnterPresetNameBanner();
          this.commonService.updateView();
          return;
        }

        const duplicate = this.scanPrintService.presets.find(preset => preset.name === this.presetName && ((preset.deviceGroupId && preset.deviceGroupId === this.scanPrintService.deviceGroupId) || (preset.deviceId && preset.deviceId === this.scanPrintService.deviceId)));

        if (duplicate) {
          this.showOverwritePresetAlert = true;
          this.commonService.updateView();
          return;
        }

        await this.save();
      } catch (error) {
        this.errorType = error.titleKey
          ? error
          : this.errorService.getErrorByStatusCode(error.status);
        this.errorCallback = this.errorService.getErrorCallback(this.errorType);

        document.activeElement.blur();
        this.presetName = "";
        this.disableOk = true;
        this.commonService.updateView();
        await this.scanPrintService.getPresets();
        this.ok();
      }
    }

    async save() {
      try {
        // Saving Preset
        const {
          printSettings,
          emailSettings,
          sftpSettings,
          smbSettings,
          jobSplit,
          jobSplitPages,
        } = this.destinationsService;
        this.presets = {};

        if (printSettings) {
          this.presets.PRINT = {
            QUANTITY: this.destinationsService.copies,
            TWOSIDEDPRINT: this.destinationsService.twoSidedPrint,
            OUTPUTCOLOR: this.destinationsService.outputColor,
            STAPLE: this.destinationsService.enableStapling,
          };
        }

        if (emailSettings) {
          this.presets.EMAIL = {
            TO: this.destinationsService.recipients,
          };
        }

        if (sftpSettings) {
          this.presets.SFTP = {
            FOLDER: this.destinationsService.sftpTarget,
          };
        }

        if (smbSettings) {
          this.presets.SMB = {
            FOLDER: this.destinationsService.smbTarget,
          };
        }

        if (jobSplit) {
          this.presets.JOBSPLIT = {
            COUNT: jobSplitPages,
          };
        }

        await this.scanPrintService.savePresets(
          this.presetName,
          this.presets,
          this.sharePresetType
        );
        this.showAlertSaved = true;

        this.destinationsService.presetBackup = this.scanPrintService.getPresetAsArray(this.presets);
        this.destinationsService.presetBackupName = this.presetName;
        this.destinationsService.presetBackupIsShared = this.sharePresetType;

        this.destinationsService.isEmailSettingsChanged = false;
        this.destinationsService.isPrintSettingsChanged = false;
        this.destinationsService.isSftpSettingsChanged = false;
        this.destinationsService.isSmbSettingsChanged = false;
        this.destinationsService.isJobSplitSettingsChanged = false;

        document.activeElement.blur();
        this.presetName = "";
        this.disableOk = true;
        this.commonService.updateView();
        await this.scanPrintService.getPresets();
        this.ok();
      } catch (error) {
        throw error;
      }
    }

    onCancel() {
      this.resetForm();
      this.cancel();
    }

    showEnterPresetNameBanner() {
      angular.element("#enter-preset-name-banner").xrxbanner("call");
    }

    presetNameClick() {
      this.editing = true;
      this.commonService.updateView();
    }

    presetNameClose() {
      this.editing = false;
      this.commonService.updateView();
    }

    presetNameChanged() {
      this.presetNameRequired = false;
      this.presetNameInvalid = false;
      this.presetNameBackup = angular.copy(this.presetName);
      if (this.presetName === "") {
        this.presetNameRequired = true;
      }
      if (this.presetNameRequired || this.presetNameInvalid) {
        this.disableOk = true;
      } else {
        this.disableOk = false;
      }
      this.commonService.updateView();
    }

    presetNameCanceled() {
      this.presetName = this.presetNameBackup;
      this.commonService.updateView();
    }

    onBeforeClosePopup() {
      this.presetName = null;
      this.presetNameBackup = null;
      this.presetNameRequired = false;
    }

    hasScroll() {
      return this.screenSize === 7
        ? this.destinationsService.recipientsBackup.length * 63 > 324
        : this.destinationsService.recipientsBackup.length * 63 > 420;
    }

    onSelectShareOption(item) {
      this.sharePreset = item.value;
      if (this.sharePreset == "false") {
        this.sharePresetType = false;
      } else {
        this.sharePresetType = true;
      }
    }

    closeAlert() {
      this.cancel();
      this.showAlertSaved = false;
      this.commonService.updateView();
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#destinations-destinations-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth,
        });
      });
    }
  },
};

/* --------------------------------------------------------------------------------------
landing.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const landingComponent = {
  template: `
        <activity display="vm.loading" label="{{ 'LOADING_SCAN_WITH_PRINT' | translate }}" on-close="vm.onLoadingClose()"></activity>
        <div xw-alert display="vm.maintenance" class="maintenance-indicator" close-button on-close="vm.exitApp()">
            <div class="center-activity">
                <div xw-activity widget-size="xrx-large"></div>
            </div>
            <p class="white-text"><strong>{{ 'INSTALLING_APP_UPDATES' | translate }}</strong></p>
            <div class="error-description">
                <p class="activity-subtitle">{{ 'THIS_MAY_TAKE_SEVERAL_MINUTES' | translate }}</p>
                <p class="activity-subtitle">{{ 'CLOSE_ALERT_CONTINUE_WORKING' | translate }}</p>
            </div>
        </div>
        <div class="hidden-maintenance-override" ng-show="vm.maintenance" ng-click="vm.maintenanceOff()"></div>
        <error-message
          ng-if="vm.errorType"
          error-type="vm.errorType"
          on-close="vm.onErrorClose()"
        ></error-message>
    `,
  controllerAs: "vm",
  controller: class landingController {
    /* @ngInject */
    constructor(
      apiClientService,
      shellDataBagService,
      scanPrintService,
      commonService,
      errorService,
      deviceInfoService,
      destinationsService,
      $state
    ) {
      this.apiClientService = apiClientService;
      this.shellDataBagService = shellDataBagService;
      this.scanPrintService = scanPrintService;
      this.commonService = commonService;
      this.deviceInfoService = deviceInfoService;
      this.destinationsService = destinationsService;
      this.errorService = errorService;
      this.$state = $state;
    }
    async $onInit() {
      this.loading = true;
      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      this.loading = false;
      if (isInMaintainanceMode) {
        this.maintenance = true;
        this.onLoadingClose = () =>
          setInterval(() => this.getAvailability(), 3000);
      } else {
        this.onLoadingClose = async () => {
          this.shellDataBagService.setData();
          const shellData = this.shellDataBagService.getData();
          const { deviceGroupId, deviceId, smb, sftp } = shellData;
          this.scanPrintService.deviceGroupId = deviceGroupId;
          this.scanPrintService.deviceId = deviceId;
          this.scanPrintService.smbConfig = smb === "true";
          this.scanPrintService.sftpConfig = sftp === "true";

          // SFTP Settings
          this.scanPrintService.sftpHost = atob(shellData["x-sftp-host"]);
          this.scanPrintService.sftpPort = atob(shellData["x-sftp-port"]);
          this.scanPrintService.sftpUsername = atob(
            shellData["x-sftp-username"]
          );
          this.scanPrintService.sftpPassword = atob(
            shellData["x-sftp-password"]
          );
          this.scanPrintService.sftpExternal =
            atob(shellData["x-sftp-external"]) === "true";
          this.scanPrintService.sftpRoot = atob(shellData["x-sftp-root"]);

          // SMB Settings
          this.scanPrintService.smbHost = atob(shellData["x-smb-host"]);
          this.scanPrintService.smbUsername = atob(shellData["x-smb-username"]);
          this.scanPrintService.smbPassword = atob(shellData["x-smb-password"]);
          this.scanPrintService.smbRoot = atob(shellData["x-smb-root"]);

          // Hard Coded SFTP Settings
          // this.scanPrintService.sftpHost = "192.168.1.20";
          // this.scanPrintService.sftpPort = 22;
          // this.scanPrintService.sftpUsername = "SFTPUser";
          // this.scanPrintService.sftpPassword = "4I%^N%7AN!bJpBZ6dNFy";
          // this.scanPrintService.sftpExternal = false;
          // this.scanPrintService.sftpRoot = "/Azer";

          // // Hard Coded SMB Settings
          // this.scanPrintService.smbHost = "192.168.1.52";
          // this.scanPrintService.smbUsername = "testuser";
          // this.scanPrintService.smbPassword = "B@113ch0";
          // this.scanPrintService.smbRoot = "smb";

          this.loading = true;
          this.commonService.updateView();

          const isAltaLink = await this.commonService.isAltalink();
          await this.scanPrintService.getPresets();

          try {
            await this.deviceInfoService.getDeviceCapabilities();
            await this.destinationsService.setOptions(!isAltaLink);
          } catch (error) {
            this.errorType = error.titleKey
              ? error
              : this.errorService.getErrorByStatusCode(error.status);
          } finally {
            this.commonService.updateView();
          }

          this.loading = false;
          this.commonService.updateView();

          if (this.scanPrintService.presets.length === 0) {
            this.$state.go("destinations");
          } else {
            this.$state.go("main");
          }
        };
      }
      this.commonService.updateView();
    }
    async getAvailability() {
      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      if (!isInMaintainanceMode) this.maintenanceOff();
    }
    maintenanceOff() {
      this.maintenance = false;
      this.onMaintenanceClose = () => this.$state.go("main");
    }
    async getMaintenanceModeValue() {
      const maintenanceConfig = await this.apiClientService.$http.get(
        "maintenance.json"
      );
      return maintenanceConfig.data.maintenanceMode;
    }
    exitApp() {
      this.commonService.exitApp();
    }
  },
};

/* --------------------------------------------------------------------------------------
   destinations.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const destinationsComponent = {
  template: require("./destinations.html"),
  controllerAs: "vm",
  controller: class destinationsController {
    /* @ngInject */
    constructor(
      commonService,
      scanPrintService,
      destinationsService,
      errorService,
      $state
    ) {
      this.commonService = commonService;
      this.scanPrintService = scanPrintService;
      this.destinationsService = destinationsService;
      this.errorService = errorService;
      this.$state = $state;
    }

    async $onInit() {
      this.loading = true;
      this.commonService.updateView();
      this.screenSize = this.commonService.getScreenSize();
      this.boundingBoxHeight = this.screenSize === 7 ? 370 : 472;
      this.boundingBoxWidth = this.screenSize === 7 ? 278 : 350;
      const isAltaLink = await this.commonService.isAltalink();
      if (!this.destinationsService.isBackFromScan) {
        await this.destinationsService.setOptions(!isAltaLink);
      }
      await this.scanPrintService.getPresets();
      if (this.destinationsService.presetBackupName !== "") {
        await this.destinationsService.setPresetOptions(!isAltaLink);
      }
      this.refreshBoundingBox();
      this.loading = false;
      this.commonService.updateView();
      angular
        .element("#two-sided-destinations-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#two-sided-destinations-selector-menu li")
        .eq(this.destinationsService.twoSidedPrintIndex || 0)
        .addClass("ui-state-selected");
    }

    async ok() {
      this.$state.go("scan");
      this.commonService.updateView();
    }

    cancel() {
      this.destinationsService.isBackFromScan = false;
      this.destinationsService.presetBackupName = "";
      this.destinationsService.presetBackup = [];
      this.$state.go("main");
      this.commonService.updateView();
    }

    async reset() {
      this.resetBanner = true;
      await this.destinationsService.setDefaults();
      if (
        this.destinationsService.presetBackup.length > 0 &&
        this.destinationsService.presetBackupName !== ""
      ) {
        await this.destinationsService.setPresetOptions();
      }
      this.refreshBoundingBox();
      this.commonService.updateView();
    }

    get hasPresets() {
      return this.scanPrintService.presets.length > 0;
    }

    get checkDisableOkButton() {
      return (
        !this.destinationsService.printSettings &&
        (!this.destinationsService.emailSettings || this.destinationsService.recipients.length === 0) &&
        !this.destinationsService.sftpSettings &&
        !this.destinationsService.smbSettings
      );
    }

    get checkDisableOkButtonStyle() {
      return (
        !this.destinationsService.printSettings &&
        (!this.destinationsService.emailSettings || this.destinationsService.recipients.length === 0) &&
        !this.destinationsService.sftpSettings &&
        !this.destinationsService.smbSettings
      );
    }

    selectTwoSidedPrintOptions(value) {
      angular
        .element("#two-sided-destinations-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#two-sided-destinations-selector-menu li")
        .eq(value.index)
        .addClass("ui-state-selected");
      this.destinationsService.twoSidedPrintIndex = value.index;
      this.destinationsService.twoSidedPrint = this.destinationsService.twoSidedPrintOptions[
        value.index
      ].key;
      this.destinationsService.twoSidedPrintText = value.label;
      this.destinationsService.checkPrintChanged();
      this.commonService.updateView();
    }

    selectOriginalOrientation(value) {
      this.destinationsService.originalOrientation = this.destinationsService.originalOrientationOptions[
        value.index
      ].key;
      this.destinationsService.originalOrientationText = value.label;
      this.commonService.updateView();
    }

    selectOriginalType(value) {
      this.destinationsService.originalType = this.destinationsService.originalTypeOptions[
        value.index
      ].key;
      this.destinationsService.originalTypeImage = this.destinationsService.originalTypeOptions[
        value.index
      ].img;
      this.destinationsService.originalTypeText = value.label;
      this.commonService.updateView();
    }

    selectOriginalSize(value) {
      const selectedSize = this.destinationsService.originalSizeOptions[
        value.index
      ];
      this.destinationsService.originalSize = selectedSize.key;
      this.destinationsService.originalSizeText = selectedSize.value;
      this.destinationsService.originalSizeOrientation =
        selectedSize.orientation;
      this.commonService.updateView();
    }

    selectOutputColor(value) {
      this.destinationsService.outputColor = this.destinationsService.outputColorOptions[
        value.index
      ].key;
      this.destinationsService.outputColorText = value.label;
      this.destinationsService.checkPrintChanged();
      this.commonService.updateView();
    }

    getOrientationGlyph(orientation) {
      switch (orientation) {
        case "L":
          return "glyphicon-short-edge-feed";
        case "P":
          return "glyphicon-long-edge-feed";
        default:
          return null;
      }
    }

    errorServiceselectResolution(value) {
      this.destinationsService.resolution = this.destinationsService.resolutionOptions[
        value.index
      ].key;
      this.destinationsService.resolutionText = value.label;
      this.commonService.updateView();
    }

    selectLanguage(item) {
      this.destinationsService.language = item;
      this.commonService.updateView();
    }

    // Move to real email recipients popup place
    cancelAddNewRecipient() {
      this.showEmailRecipientsPopup = true;
      this.showAddRecipientPopup = false;
      this.commonService.updateView();
    }

    openAddNewRecipientPopup() {
      this.showEmailRecipientsPopup = false;
      this.showAddRecipientPopup = true;
      this.commonService.updateView();
    }

    closeAddNewRecipientPopup() {
      this.showEmailRecipientsPopup = true;
      this.showAddRecipientPopup = false;
      this.commonService.updateView();

      // TODO: Move this code when moving this in real place
      this.updateEmailRecipientsTableScroll();
    }

    updateEmailRecipientsTableScroll() {
      setTimeout(() => {
        if (this.hasScroll()) {
          angular.element("#recipients-table-wrapper").removeClass("no-scroll");
          angular
            .element("#recipients-wrapper")
            .xrxscrollable({ scroll: true });
          angular.element("#recipients-wrapper .shadow").removeClass("hide");
        } else {
          angular.element("#recipients-table-wrapper").addClass("no-scroll");
          angular.element("#recipients-wrapper").xrxscrollable("destroy");
          angular.element("#recipients-wrapper .shadow").addClass("hide");
        }
        this.commonService.updateView();
      }, 500);
    }

    emailRecipientsHasScroll() {
      return this.screenSize === 7
        ? this.destinationsService.recipientsBackup.length * 69 > 308
        : this.destinationsService.recipientsBackup.length * 63 > 420;
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#destinations-destinations-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth,
        });
      });
    }
  },
};

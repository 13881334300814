/* --------------------------------------------------------------------------------------
   scanSettingsSection.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanSettingsSectionComponent = {
    template: require('./scanSettingsSection.html'),
    bindings: {
        reset: '&'
    },
    controllerAs: 'vm',
    controller: class scanSettingsSectionController {
        /* @ngInject */
        constructor(commonService, scanService, $state) {
            this.commonService = commonService
            this.scanService = scanService
            this.$state = $state
        }
        $onInit() {
            this.screenSize = this.commonService.getScreenSize()
        }
        async openResetBanner() {
          await this.reset()
          angular.element('#reset-banner').xrxbanner('call')
        }
    }
}

/* --------------------------------------------------------------------------------------
   scanCopy.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class scanPrintService {
  /*@ngInject*/
  constructor(
    apiClientService,
    deviceInfoService,
    shellDataBagService,
    commonService,
    errorService,
    $http,
    $translate
  ) {
    this.deviceInfoService = deviceInfoService;
    this.apiClientService = apiClientService;
    this.shellDataBagService = shellDataBagService;
    this.errorService = errorService;
    this.commonService = commonService;
    this.$http = $http;
    this.$translate = $translate;
    this.presets = null;
    this.deviceId = null;
    this.deviceGroupId = null;
  }

  async getAPIVersion() {
    const apiVesion = await this.apiClientService.get("/version");
    return apiVesion;
  }

  /* RETURN FORMAT OF THIS FUNCTION
  [
    {
      isShared: boolean,
      name: string,
      preset: [
        {
          name: string,
          values: [
            {
              name: string,
              value: string
            }
          ]
        }
      ]
    }
  ]
  */
  async getPresets() {
    const presetObject = await this.apiClientService.get(
      "/v1/presets?device_id=" +
        this.deviceId +
        "&device_group_id=" +
        this.deviceGroupId
    );
    if (!presetObject || presetObject.length === 0) {
      this.presets = [];
    } else {
      this.presets = presetObject.map((preset) => ({
        ...preset,
        preset: this.getPresetAsArray(preset.preset),
      }));
    }
    return this.presets;
  }

  getPresetAsArray(presetObject) {
    const presetArray = Object.keys(presetObject).reduce((accum, key) => {
      return [
        ...accum,
        { name: key, values: this.getPresetValuesAsArray(presetObject[key]) },
      ];
    }, []);
    return presetArray;
  }

  getPresetValuesAsArray(presetValuesObject) {
    const presetValuesArray = Object.keys(presetValuesObject).reduce(
      (accum, key) => {
        return [...accum, { name: key, value: presetValuesObject[key] }];
      },
      []
    );
    return presetValuesArray;
  }

  savePresets(name, presets, isShared) {
    const payload = {};
    if (isShared) {
      payload["device_group_id"] = this.deviceGroupId;
    } else {
      payload["device_id"] = this.deviceId;
    }
    payload.name = name;
    payload.presets = presets;
    return this.apiClientService.post("/v1/presets", payload);
  }

  deletePreset(name, isShared) {
    return this.apiClientService.delete(
      "/v1/presets?" +
        (isShared
          ? "&device_group_id=" + this.deviceGroupId
          : "&device_id=" + this.deviceId) +
        "&name=" +
        encodeURIComponent(isShared ? "_" + name : name)
    );
  }
  
  async getPaperPreference() {
    try {
      const a4sizePref = await this.deviceInfoService.getPaperPreferenceA4()
      console.log("===== a4sizePref =======", a4sizePref)
      this.paperSize = a4sizePref ? 'A4' : 'Letter'
    } catch (error) {
      throw error
    }
  }
}

/* --------------------------------------------------------------------------------------
  destinations.service.js
  Copyright © 2020 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

import { resolvePolicies } from "@uirouter/angularjs";

export class destinationsService {
  /* @ngInject*/
  constructor(
    xeroxEIPParameters,
    languagesService,
    deviceInfoService,
    $translate
  ) {
    this.xeroxEIPParameters = xeroxEIPParameters;
    this.languagesService = languagesService;
    this.deviceInfoService = deviceInfoService;
    this.$translate = $translate;
    this.recipients = [];
    this.recipientsBackup = [];
    this.presetBackup = [];
    this.presetBackupName = "";
    this.presetBackupIsShared = false;
    this.sftpTarget = "";
    this.smbTarget = "";
    this.enableStapling = false;
    this.jobSplit = false;
    this.jobSplitPages = 1;

    this.isEmailSettingsChanged = false;
    this.isPrintSettingsChanged = false;
    this.isSftpSettingsChanged = false;
    this.isSmbSettingsChanged = false;
    this.isJobSplitSettingsChanged = false;
    this.isBackFromScan = false;
  }

  async setOptions(isVersa) {
    this.copies = 1;
    this.twoSidedPrintOptions = this.xeroxEIPParameters.sides.filter(
      (side) =>
        side.scope.includes("scan") &&
        side.device.includes(isVersa ? "Versa" : "Alta")
    );
    this.originalSizeOptions = !isVersa
      ? this.xeroxEIPParameters.sizes
      : this.xeroxEIPParameters.sizes.filter((s) => !s.a3);
    const deviceCapabilities = await this.deviceInfoService.getDeviceCapabilities();
    this.stapleSupport = deviceCapabilities.stapleSupport;
    await this.setDefaults();
  }

  async setPresetOptions() {
    return new Promise(async (resolve) => {
      if (this.presetBackup.length === 0 || !this.presetBackupName) {
        resolve(false);
      }
      const printSettings = this.presetBackup.find(
        (item) => item.name === "PRINT"
      );
      if (printSettings) {
        this.printSettings = true;
        this.copies =
          printSettings.values.find((item) => item.name === "QUANTITY").value ||
          1;

        this.twoSidedPrint =
          printSettings.values.find((item) => item.name === "TWOSIDEDPRINT")
            .value || "OneSided";
        this.twoSidedPrintIndex = this.twoSidedPrintOptions.findIndex(
          (item) => item.key === this.twoSidedPrint
        );
        this.twoSidedPrintImage = this.twoSidedPrintOptions[
          this.twoSidedPrintIndex
        ].img;
        this.twoSidedPrintText = await this.$translate(
          this.twoSidedPrintOptions[this.twoSidedPrintIndex].translationKey
        );

        this.enableStapling = printSettings.values.find(
          (item) => item.name === "STAPLE"
        )
          ? printSettings.values.find((item) => item.name === "STAPLE").value
          : false;
      } else {
        this.printSettings = false;
      }

      const emailSettings = this.presetBackup.find(
        (item) => item.name === "EMAIL"
      );

      if (emailSettings) {
        this.emailSettings = true;
        const recipients = emailSettings.values.find(
          (item) => item.name === "TO"
        ).value;
        this.recipients = recipients;
      } else {
        this.recipients = [];
        this.emailSettings = false;
      }

      const sftpSettings = this.presetBackup.find(
        (item) => item.name === "SFTP"
      );
      if (sftpSettings) {
        this.sftpSettings = true;
        this.sftpTarget = sftpSettings.values[0].value;
      } else {
        this.sftpSettings = false;
        this.sftpTarget = "";
      }

      const smbSettings = this.presetBackup.find((item) => item.name === "SMB");
      if (smbSettings) {
        this.smbSettings = true;
        this.smbTarget = smbSettings.values[0].value;
      } else {
        this.smbSettings = false;
        this.smbTarget = "";
      }

      const jobSplitSettings = this.presetBackup.find(
        (item) => item.name === "JOBSPLIT"
      );
      if (jobSplitSettings) {
        this.jobSplit = true;
        this.jobSplitPages = parseInt(jobSplitSettings.values[0].value, 10);
      } else {
        this.jobSplit = false;
      }
      resolve(true);
    });
  }

  async setDefaults() {
    this.printSettings = true;
    this.emailSettings = false;
    this.sftpSettings = false;
    this.smbSettings = false;
    this.twoSidedPrint = this.twoSidedPrintOptions[0].key;
    this.twoSidedPrintImage = this.twoSidedPrintOptions[0].img;
    this.twoSidedPrintText = await this.$translate(
      this.twoSidedPrintOptions[0].translationKey
    );
    this.jobSplit = false;
    this.jobSplitPages = 1;
    this.copies = 1;
    let storedRecipients = this.recipientsBackup;
    this.recipients = storedRecipients ? storedRecipients : [];
    this.sftpTarget = "";
    this.smbTarget = "";
    this.enableStapling = false;
  }

  checkEmailChanged() {
    const emailSettings = this.presetBackup.find(
      (item) => item.name === "EMAIL"
    );

    if (
      !!emailSettings !== this.emailSettings ||
      (this.emailSettings &&
        JSON.stringify(this.recipients) !==
          JSON.stringify(
            emailSettings.values.find((item) => item.name === "TO").value
          ))
    ) {
      this.isEmailSettingsChanged = true;
    } else {
      this.isEmailSettingsChanged = false;
    }
  }

  checkSftpChanged() {
    const sftpSettings = this.presetBackup.find((item) => item.name === "SFTP");

    this.isSftpSettingsChanged = !!sftpSettings !== this.sftpSettings;
  }

  checkSmbChanged() {
    const smbSettings = this.presetBackup.find((item) => item.name === "SMB");

    this.isSmbSettingsChanged = !!smbSettings !== this.smbSettings;
  }

  async checkPrintChanged() {
    await new Promise((r) => setTimeout(r, 2000));
    const printSettings = this.presetBackup.find(
      (item) => item.name === "PRINT"
    );

    if (!!printSettings !== this.printSettings) {
      this.isPrintSettingsChanged = true;
      return;
    }

    if (!this.printSettings) {
      this.isPrintSettingsChanged = false;
      return;
    }

    const copies =
      printSettings.values.find((item) => item.name === "QUANTITY").value || 1;

    const twoSidedPrint =
      printSettings.values.find((item) => item.name === "TWOSIDEDPRINT")
        .value || "OneSided";

    if (this.copies !== copies || this.twoSidedPrint !== twoSidedPrint) {
      this.isPrintSettingsChanged = true;
    } else {
      this.isPrintSettingsChanged = false;
    }
  }

  checkJobSplitChanged() {
    const jobSplitSettings = this.presetBackup.find(
      (item) => item.name === "JOBSPLIT"
    );

    if (
      !!jobSplitSettings !== this.jobSplitSettings ||
      (this.jobSplitSettings &&
        parseInt(jobSplitSettings.values[0].value, 10) !== this.jobSplitPages)
    ) {
      this.isJobSplitSettingsChanged = true;
    } else {
      this.isJobSplitSettingsChanged = false;
    }
  }
}

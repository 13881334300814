/* --------------------------------------------------------------------------------------
   jobSplitSettingsPopup.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const jobSplitSettingsPopupComponent = {
    template: require("./jobSplitSettingsPopup.html"),
    bindings: {
        display: "=",
        ok: "&",
        cancel: "&"
    },
    controllerAs: "vm",
    controller: class jobSplitSettingsPopupController {
        /* @ngInject */
        constructor(commonService, destinationsService, scanPrintService, $scope) {
            this.commonService = commonService;
            this.destinationsService = destinationsService;
            this.scanPrintService = scanPrintService;
            this.$scope = $scope;
        }
  
        $onInit() {
            this.screenSize = this.commonService.getScreenSize();
            this.isBlur = true;
            this.isReset = false;
            this.jobSplit = this.destinationsService.jobSplit
            this.jobSplitPages = this.destinationsService.jobSplitPages
        }

        onOk() {            
            if (this.jobSplit && !this.jobSplitPages) return this.showEnterNumberBanner()
            if (this.jobSplitPages > 100) {
                this.jobSplitPages = 100
                this.isReset = true;
                return this.showMaxNumberBanner();
            }
            this.destinationsService.jobSplit = this.jobSplit
            this.destinationsService.jobSplitPages = this.jobSplitPages;

            this.destinationsService.checkJobSplitChanged();
            this.commonService.updateView();
            this.ok();
        }

        onCancel() {
            this.cancel();
        }

        showEnterNumberBanner() {
            angular.element('#enter-number-banner').xrxbanner('open');
        }
        showMaxNumberBanner() {
            angular.element('#maximum-number-banner').xrxbanner('open');
        }

        selectJobSplitMode(mode) {
            this.jobSplit = mode;
            if (mode) this.jobSplitPages = 1
        }

        jobSplitDataCountChanged(v) {
            this.isBlur = false
            this.isReset = false
            this.removeError()
        }
        removeError() {
          this.error = null
          this.$scope.$apply()
        }
        onBlur() {
            this.isBlur = true;
            this.commonService.updateView();
        }
    }
};

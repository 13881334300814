/* --------------------------------------------------------------------------------------
   destinationsSettingsSection.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const destinationsSettingsSectionComponent = {
  template: require("./destinationsSettingsSection.html"),
  bindings: {
    reset: "&",
  },
  controllerAs: "vm",
  controller: class destinationsSettingsSectionController {
    /* @ngInject */
    constructor(
      commonService,
      scanPrintService,
      privacyStatementLanguageService,
      destinationsService,
      $translate,
      $state
    ) {
      this.commonService = commonService;
      this.scanPrintService = scanPrintService;
      this.privacyStatementLanguageService = privacyStatementLanguageService;
      this.destinationsService = destinationsService;
      this.$translate = $translate;
      this.$state = $state;
    }

    async $onInit() {
      this.screenSize = this.commonService.getScreenSize();

      this.showOverwritePresetAlert = false;
      this.overwriteAlertButtons = [
        {
          alertIcon: "glyphicon-close",
          text: await this.$translate("CANCEL"),
          class: "forest xglicon reverted full-width",
          click: () => this.cancelOverwrite(),
        },
        {
          alertIcon: "glyphicon-download_square",
          text: await this.$translate("SAVE_AS_NEW_PRESET"),
          class: "forest xglicon reverted full-width",
          click: () => this.saveAsNewPreset(),
        },
        {
          alertIcon: "glyphicon-overwrite_square",
          text: await this.$translate("OVERWRITE"),
          class: "forest xglicon reverted full-width",
          click: () => this.overwrite(),
        },
      ];
      this.commonService.updateView();
    }

    cancelOverwrite() {
      this.showOverwritePresetAlert = false;
      this.commonService.updateView();
    }

    saveAsNewPreset() {
      this.openSavePresetPopup();
      this.showOverwritePresetAlert = false;
      this.commonService.updateView();
    }

    async overwrite() {
      const {
        printSettings,
        emailSettings,
        sftpSettings,
        smbSettings,
        jobSplit,
        jobSplitPages,
        presetBackupName,
        presetBackupIsShared,
      } = this.destinationsService;
      this.presets = {};

      if (printSettings) {
        this.presets.PRINT = {
          QUANTITY: this.destinationsService.copies,
          TWOSIDEDPRINT: this.destinationsService.twoSidedPrint,
          OUTPUTCOLOR: this.destinationsService.outputColor,
          STAPLE: this.destinationsService.enableStapling,
        };
      }

      if (emailSettings) {
        this.presets.EMAIL = {
          TO: this.destinationsService.recipients,
        };
      }

      if (sftpSettings) {
        this.presets.SFTP = {
          FOLDER: this.destinationsService.sftpTarget,
        };
      }

      if (smbSettings) {
        this.presets.SMB = {
          FOLDER: this.destinationsService.smbTarget,
        };
      }

      if (jobSplit) {
        this.presets.JOBSPLIT = {
          COUNT: jobSplitPages,
        };
      }

      await this.scanPrintService.savePresets(
        presetBackupName,
        this.presets,
        presetBackupIsShared
      );
      this.showAlertOverwrite = true;

      this.showOverwritePresetAlert = false;
      this.destinationsService.presetBackup = this.scanPrintService.getPresetAsArray(
        this.presets
      );

      this.destinationsService.isEmailSettingsChanged = false;
      this.destinationsService.isJobSplitSettingsChanged = false;
      this.destinationsService.isPrintSettingsChanged = false;
      this.destinationsService.isSftpSettingsChanged = false;
      this.destinationsService.isSmbSettingsChanged = false;

      this.commonService.updateView();
    }

    closeAlert() {
      this.showAlertOverwrite = false;
      this.commonService.updateView();
    }

    get hasPresets() {
      return this.scanPrintService.presets.length > 0;
    }

    async openResetBanner() {
      await this.reset();
      angular.element("#reset-banner").xrxbanner("call");
    }

    get canSaveAsPreset() {
      // console.log(this.destinationsService.presetBackup.length === 0);
      // console.log(this.destinationsService.isEmailSettingsChanged);
      // console.log(this.destinationsService.isJobSplitSettingsChanged);
      // console.log(this.destinationsService.isPrintSettingsChanged);
      // console.log(this.destinationsService.isSftpSettingsChanged);
      // console.log(this.destinationsService.isSmbSettingsChanged);
      return (
        (this.destinationsService.presetBackup.length === 0 ||
          this.destinationsService.isEmailSettingsChanged ||
          this.destinationsService.isJobSplitSettingsChanged ||
          this.destinationsService.isPrintSettingsChanged ||
          this.destinationsService.isSftpSettingsChanged ||
          this.destinationsService.isSmbSettingsChanged) &&
        (this.destinationsService.printSettings ||
          this.destinationsService.emailSettings ||
          this.destinationsService.sftpSettings ||
          this.destinationsService.smbSettings)
      );
    }

    get showJobSplitFeedback() {
      return (
        (this.destinationsService.emailSettings &&
          (!this.destinationsService.sftpSettings ||
            !this.scanPrintService.sftpExternal)) ||
        (this.destinationsService.emailSettings &&
          this.destinationsService.sftpSettings &&
          this.scanPrintService.sftpExternal) ||
        (!this.destinationsService.emailSettings &&
          this.destinationsService.sftpSettings &&
          this.scanPrintService.sftpExternal)
      );
    }

    save() {
      if (
        this.destinationsService.presetBackup &&
        this.destinationsService.presetBackup.length > 0
      ) {
        this.openOverwritePresetAlert();
      } else {
        this.openSavePresetPopup();
      }
    }

    openPrivacyStatementPopup() {
      document.activeElement.blur();
      this.privacyStatement = true;
      this.commonService.updateView();
    }

    privacyStatementClosed() {
      document.activeElement.blur();
      this.privacyStatement = false;
      this.commonService.updateView();
    }

    cancelSavePresetPopup() {
      this.showSavePresetPopup = false;
      this.commonService.updateView();
    }

    closeSavePresetPopup() {
      this.showSavePresetPopup = false;
      this.refreshBoundingBox();
      this.commonService.updateView();
    }

    openOverwritePresetAlert() {
      this.showOverwritePresetAlert = true;
      this.commonService.updateView();
    }

    openSavePresetPopup() {
      if (this.canSaveAsPreset) {
        this.showSavePresetPopup = true;
        this.commonService.updateView();
        setTimeout(() => {
          angular.element("#save-preset-name-input").getkeyboard().reveal();
        }, 500);
      }
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#destinations-destinations-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth,
        });
      });
    }
  },
};

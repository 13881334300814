/* --------------------------------------------------------------------------------------
   scan.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanComponent = {
  template: require("./scan.html"),
  controllerAs: "vm",
  controller: class scanController {
    /* @ngInject */
    constructor(
      appSettings,
      commonService,
      destinationsService,
      scanPrintService,
      jobStatusService,
      scanService,
      printService,
      deviceInfoService,
      $state,
      errorService,
      moment,
      $translate
    ) {
      this.appSettings = appSettings;
      this.commonService = commonService;
      this.destinationsService = destinationsService;
      this.scanPrintService = scanPrintService;
      this.scanService = scanService;
      this.printService = printService;
      this.deviceInfoService = deviceInfoService;
      this.jobStatusService = jobStatusService;
      this.$state = $state;
      this.moment = moment;
      this.errorService = errorService;
      this.$translate = $translate
    }

    async $onInit() {
      this.loading = true;
      this.commonService.updateView();
      this.screenSize = this.commonService.getScreenSize();
      const isAltaLink = await this.commonService.isAltalink();

      this.fileProperties = {
        filename: "Xerox Scan [Date & Time].pdf",
      };
      this.showAddRecipientPopup = false;
      this.showEmailRecipientsPopup = false;
      try {
        if (!this.scanService.init) {
          await this.scanService.setOptions(isAltaLink);
          await this.scanService.setDefaultLanguage();
        }

        if (this.appSettings.warmupEnabled) {
          console.log("***** Print Warm Up Started *****");
          const fakePrintJobId = await this.printService.SubmitFakePrintJob();
          console.log("***** Fake Print Job Id *****", fakePrintJobId);
          const cancelResponse = await this.jobStatusService.cancelJob(
            "WorkflowScanning",
            fakePrintJobId
          );
          console.log("***** Job Canceled! *****", cancelResponse);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.commonService.updateView();

        this.refreshScroll();
      }

      setTimeout(() => {
        angular
          .element("#resolution-selector-menu li")
          .removeClass("ui-state-focus ui-state-selected");
        angular
          .element("#resolution-selector-menu li")
          .eq(4)
          .addClass("ui-state-selected"); // By default 5th one 300dpi is set
      }, 500)
    }

    refreshScroll() {
      if (angular.element('#scan-destinations-scroll')) {
        angular.element('#scan-destinations-scroll').xrxscrollable('refresh');
        this.commonService.updateView();
      } else {
        setTimeout(() => {
          this.refreshScroll();
        }, 300);
      }
    }

    async printJob(sessionId, fileName, printOptions) {
      try {
        this.printing = true;
        this.commonService.updateView();
        const jobId = await this.printService.SubmitPrint(
          sessionId,
          fileName,
          printOptions
        );
        await this.jobStatusService.getPrintFinishedStatus(jobId);
        this.printing = false;
        this.commonService.updateView();
        return true;
      } catch (error) {
        this.processing = false;
        this.printing = false;
        this.commonService.updateView();

        // Emulate email not sent behavior
        // this.showErrorSendFailMessage();
        // Emulate transmission fail behavior
        this.showErrorTransmissionFailed();
      }
    }

    showErrorJobManagementFailure() {
      this.errorType = this.errorService.errorTypes.jobManagementFailure;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    showErrorProcessingFailure() {
      this.errorType = this.errorService.errorTypes.processingFailure;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    showErrorEmailCannotBeSend() {
      this.errorType = this.errorService.errorTypes.emailCannotBeSent;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    showErrorTransmissionFailed() {
      this.errorType = this.errorService.errorTypes.transmissionFailed;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    showErrorDocumentCannotBeSentToSFTP() {
      this.errorType = this.errorService.errorTypes.documentCannotBeSentToSFTP;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    showErrorDocumentCannotBeSentToDestination() {
      this.errorType = this.errorService.errorTypes.documentCannotBeSentToDestination;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    showErrorDocumentCannotBeSentToFolder() {
      this.errorType = this.errorService.errorTypes.documentCannotBeSentToFolder;
      this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
      this.commonService.updateView();
      this.processing = false;
      this.printing = false;
    }

    showCustomError(error) {
      this.errorType = this.errorService.errorTypes.errorHasOccured;
      if (!!error.status) {
        this.errorType.errorCode = error.status;
      }
      this.processing = false;
      this.printing = false;
      this.commonService.updateView();
    }

    pathJoin(parts, sep) {
      var separator = sep || "/";
      var replace = new RegExp(separator + "{1,}", "g");
      return parts.join(separator).replace(replace, separator);
    }

    async ok() {
      this.destinationsService.isBackFromScan = false;
      this.processing = true;
      this.processLabel = "SCANNING";
      this.currentScanProfileId = this.commonService.getNewGuid();
      let fileName = this.fileProperties.filename;
      if (!fileName.includes("[Date & Time].pdf")) {
        fileName = fileName + " [Date & Time].pdf";
      }
      fileName = fileName.replace(
        "[Date & Time].pdf",
        `${this.moment().format("YYYY-MM-DD hh.mm.ss A")}.pdf`
      );

      try {
        let data = {
          ticket_id: this.currentScanProfileId,
          name: fileName,
          duplex: this.scanService.twoSidedScan,
          colorMode: this.scanService.outputColor,
          resolution: this.scanService.resolution,
          orientation: this.scanService.originalOrientation,
          originalSize: this.scanService.originalSize,
          originalType: this.scanService.originalType,
          copy: true,
          language: this.deviceInfoService.getLanguage(),
          device_id: this.scanPrintService.deviceId,
          searchable: this.scanService.searchable,
        };

        if (this.destinationsService.jobSplit) {
          data.jobSplit = this.destinationsService.jobSplitPages;
        }

        if (this.destinationsService.emailSettings) {
          data.email_targets = this.destinationsService.recipients.join(";");
        }

        if (this.destinationsService.smbSettings) {
          data.internal_smb_target_folder =
            this.destinationsService.smbTarget !== ""
              ? this.destinationsService.smbTarget
              : "/";
        }

        if (this.destinationsService.sftpSettings) {
          if (this.scanPrintService.sftpExternal) {
            data.ext_sftp_target =
              this.destinationsService.sftpTarget !== ""
                ? this.destinationsService.sftpTarget
                : "/";
          } else {
            data.internal_sftp_target_folder =
              this.destinationsService.sftpTarget !== ""
                ? this.destinationsService.sftpTarget
                : "/";
          }
        }

        const {
          smbConfig,
          sftpConfig,
          sftpHost,
          sftpPort,
          sftpUsername,
          sftpPassword,
          sftpExternal,
          sftpRoot,
          smbHost,
          smbUsername,
          smbPassword,
          smbRoot,
        } = this.scanPrintService;

        const targetSftp = sftpExternal
          ? "external_sftp_target"
          : "internal_sftp_target";
        const targetSmb = "internal_smb_target";
        const sftp_target_folder =
          sftpRoot === "" || sftpRoot === "\\" ? "/" : sftpRoot;
        const smb_share_root =
          smbRoot === "" || smbRoot === "\\" ? "/" : smbRoot;

        if (sftpConfig && this.destinationsService.sftpSettings) {
          data = {
            ...data,
            sftp_target: this.pathJoin([
              sftp_target_folder,
              this.destinationsService.sftpTarget,
            ]),
            [targetSftp]: {
              target_folder: this.pathJoin([
                sftp_target_folder,
                this.destinationsService.sftpTarget,
              ]),
              host: sftpHost,
              port: sftpPort,
              username: sftpUsername,
              password: sftpPassword,
            },
          };
        }

        if (smbConfig && this.destinationsService.smbSettings) {
          data = {
            ...data,
            [targetSmb]: {
              share_root: smb_share_root,
              host: smbHost,
              username: smbUsername,
              password: smbPassword,
            },
          };
        }

        const { scanJobId, jobType } = await this.scanService.scan(
          this.currentScanProfileId,
          data
        );

        const isAltaLink = await this.commonService.isAltalink();

        if (!isAltaLink) {
          await this.jobStatusService.waitScanVersa(scanJobId);
        }

        const scanJobStatus = await this.jobStatusService.getScanFinishedStatus(
          scanJobId,
          jobType,
          !isAltaLink
        );

        console.log("*** scanJobStatus ***", scanJobStatus);
        if (scanJobStatus === "Completed" || scanJobStatus === "Aborted") {
          const jobDetails = await this.jobStatusService.getScanJobDetails(
            scanJobId,
            jobType,
            !isAltaLink
          );

          console.log("*** getScanJobDetails ***", jobDetails);
          const status = this.jobStatusService.parseStatus(jobDetails.response);

          console.log("*** Scan Finished Reason ***", status.reasons);

          if (status.reasons !== "JobCompletedSuccessfully") {
            this.showErrorDocumentCannotBeSentToDestination();
          }
        }
        if (
          this.destinationsService.emailSettings ||
          this.destinationsService.sftpSettings ||
          this.destinationsService.smbSettings
        ) {
          this.processLabel = "PROCESSING";
        } else {
          this.processLabel = "PRINTING";
        }
      } catch (error) {
        this.processing = false;
        this.printing = false;
        this.commonService.updateView();
        await this.scanService.delete(this.currentScanProfileId);
        this.showErrorJobManagementFailure();
        throw error;
      }

      try {
        await this.scanService.getUploadStatus(this.currentScanProfileId);
      } catch (error) {
        this.processing = false;
        this.printing = false;
        this.commonService.updateView();
        await this.scanService.delete(this.currentScanProfileId);
        this.showErrorProcessingFailure();
        throw error;
      }

      try {
        await this.scanService.delete(this.currentScanProfileId);
      } catch (error) {
        this.processing = false;
        this.printing = false;
        this.commonService.updateView();
        this.showErrorJobManagementFailure();
        throw error;
      }

      try {
        const printOptions = {
          copies: this.destinationsService.copies,
          sides: this.destinationsService.twoSidedPrint,
        };

        if (
          this.destinationsService.stapleSupport &&
          this.destinationsService.enableStapling
        ) {
          if (this.deviceInfoService.stapleOptions.includes("Staple")) {
            printOptions.staple = "Staple";
          } else {
            if (this.deviceInfoService.stapleOptions[0] !== "None") {
              printOptions.staple = this.deviceInfoService.stapleOptions[0];
            } else {
              printOptions.staple = this.deviceInfoService.stapleOptions[1]
                ? this.deviceInfoService.stapleOptions[1]
                : "None";
            }
          }
        } else {
          printOptions.staple = "None";
        }

        console.log("**** StapleFinishing **** ", printOptions.staple);

        if (this.destinationsService.printSettings) {
          await this.printJob(
            this.currentScanProfileId,
            fileName,
            printOptions
          );
        }
      } catch (error) {
        this.processing = false;
        this.printing = false;
        this.commonService.updateView();
        this.showErrorJobManagementFailure();
        throw error;
      }

      try {
        this.processLabel = "COMPLETE";
        await new Promise((r) => setTimeout(r, 3000));
        const documentStatus = await this.scanService.getDocumentSendStatus(
          this.currentScanProfileId
        );
        if (documentStatus && documentStatus.email === "Unauthroised") {
          this.showErrorEmailCannotBeSend();
        }
        if (documentStatus && documentStatus.email === "Transmission_failed") {
          this.showErrorTransmissionFailed();
        }
        if (documentStatus && documentStatus.sftp_ext === "Unauthroised") {
          this.showErrorDocumentCannotBeSentToSFTP();
        }
        if (
          documentStatus &&
          documentStatus.sftp_ext === "Transmission_failed"
        ) {
          this.showErrorTransmissionFailed();
        }
      } catch (error) {
        this.showCustomError(error);
        this.commonService.updateView();
        throw error;
      }

      angular
        .element(".xrx-activity-indicator")
        .xrxactivityindicator("complete");
      this.commonService.updateView();

      if (!this.errorType) {
        setTimeout(() => {
          this.processing = false;
          this.printing = false;
          this.commonService.updateView();
          this.$state.go("main");
        }, 3000);
      }
    }

    cancel() {
      this.destinationsService.isBackFromScan = true;
      this.$state.go("destinations");
    }

    // Temporary function
    setProcessing() {
      setTimeout(() => {
        this.processLabel = "PROCESSING";
        this.commonService.updateView();
      }, 5000);
    }

    // Temporary function
    setComplete() {
      setTimeout(() => {
        angular
          .element(".xrx-activity-indicator")
          .xrxactivityindicator("complete");
        this.processLabel = "COMPLETE";
        this.commonService.updateView();
        this.complete();
      }, 5000);
    }

    // Temporary function
    complete() {
      setTimeout(() => {
        this.$state.go("main");
      }, 5000);
    }

    async reset() {
      this.resetBanner = true;
      this.fileProperties = {
        filename: "Xerox Scan [Date & Time].pdf",
      };
      await this.scanService.setDefaults();
      this.commonService.updateView();
    }

    checkDisableOkButton() {}
    checkDisableOkButtonStyle() {}

    selectTwoSidedScan(value) {
      this.scanService.twoSidedScan = this.scanService.twoSidedScanOptions[
        value.index
      ].key;
      this.scanService.twoSidedScanText = value.label;
      this.commonService.updateView();
    }

    selectOriginalOrientation(value) {
      this.scanService.originalOrientation = this.scanService.originalOrientationOptions[
        value.index
      ].key;
      this.scanService.originalOrientationText = value.label;
      this.commonService.updateView();
    }

    selectOriginalType(value) {
      this.scanService.originalType = this.scanService.originalTypeOptions[
        value.index
      ].key;
      this.scanService.originalTypeImage = this.scanService.originalTypeOptions[
        value.index
      ].img;
      this.scanService.originalTypeText = value.label;
      this.commonService.updateView();
    }

    selectOriginalSize(value) {
      const selectedSize = this.scanService.originalSizeOptions[value.index];
      this.scanService.originalSize = selectedSize.key;
      this.scanService.originalSizeText = value.label;
      this.scanService.originalSizeOrientation = selectedSize.orientation;
      this.commonService.updateView();
    }

    selectOutputColor(value) {
      this.scanService.outputColor = this.scanService.outputColorOptions[
        value.index
      ].key;
      this.scanService.outputColorText = value.label;
      this.commonService.updateView();
    }

    getOrientationGlyph(orientation) {
      switch (orientation) {
        case "L":
          return "glyphicon-short-edge-feed";
        case "P":
          return "glyphicon-long-edge-feed";
        default:
          return null;
      }
    }

    selectResolution(value) {
      this.scanService.resolution = this.scanService.resolutionOptions[
        value.index
      ].key;
      this.scanService.resolutionText = value.label;
      this.commonService.updateView();
      if (this.scanService.resolution === "600") {
        angular.element("#high-resolution-banner").xrxbanner("open");
      }
      
      angular
        .element("#resolution-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected");
      angular
        .element("#resolution-selector-menu li")
        .eq(value.index)
        .addClass("ui-state-selected"); // By default 5th one 300dpi is set
    }

    selectLanguage(item) {
      this.scanService.language = item;
      this.commonService.updateView();
    }
    getOriginalSizePopoverWidth() {
      //dafault values: en, da, cs
      const lang = this.$translate.use()
      if (lang == "fr") return this.screenSize == 7? 200 : 180      
      if (lang == "de") return this.screenSize == 7? 210 : 190     
      if (lang == "el") return this.screenSize == 7? 210 : 190     
      if (lang == "pl") return this.screenSize == 7? 230 : 200      
      if (lang == "pt") return this.screenSize == 7? 230 : 200      
      if (lang == "tr") return this.screenSize == 7? 250 : 220
      if (lang == "ru") return this.screenSize == 7? 250 : 220 
      if (lang == "ca") return this.screenSize == 7? 270 : 240 
      if (lang == "ro") return this.screenSize == 7? 270 : 240 
      if (lang == "nl") return this.screenSize == 7? 300 : 270 
      if (lang == "no") return this.screenSize == 7? 300 : 270
      if (lang == "sv") return this.screenSize == 7? 300 : 270
      if (lang == "hu") return this.screenSize == 7? 310 : 280
      if (lang == "it") return this.screenSize == 7? 310 : 280
      if (lang == "fi") return this.screenSize == 7? 380 : 330
      return this.screenSize == 7? 200 : 170
    }
  },
};

/* --------------------------------------------------------------------------------------
privacyStatement.component.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const privacyStatementComponent = {
  template: `
    <div
      id="privacy-action-bar"
      class="xrx-action-bar"
    >
      <div class="xrx-action-bar-label">
        <div class="action-bar-label-text">
          {{ 'PRIVACY_STATEMENT' | translate }}
        </div>
      </div>
      <button
        xw-button
        class="xrx-action-bar-button-right"
        glyph="close"
        no-text
        ng-click="vm.onClose()"
      ></button>
    </div>
    <div xw-popup display="vm.display" id="privacy-popup" custom-action-bar="#privacy-action-bar">
      <div id="privacy-statement-scroll" xw-bounding-box>
        <privacy-statement-en ng-if="vm.privacyStatementLanguageService.isDefault()"></privacy-statement-en>
        <privacy-statement-de ng-if="vm.privacyStatementLanguageService.isCurrent('de')"></privacy-statement-de>
        <privacy-statement-da ng-if="vm.privacyStatementLanguageService.isCurrent('da')"></privacy-statement-da>
        <privacy-statement-es ng-if="vm.privacyStatementLanguageService.isCurrent('es')"></privacy-statement-es>
        <privacy-statement-fr ng-if="vm.privacyStatementLanguageService.isCurrent('fr')"></privacy-statement-fr>
        <privacy-statement-it ng-if="vm.privacyStatementLanguageService.isCurrent('it')"></privacy-statement-it>
        <privacy-statement-pt ng-if="vm.privacyStatementLanguageService.isCurrent('pt')"></privacy-statement-pt>
        <privacy-statement-cs ng-if="vm.privacyStatementLanguageService.isCurrent('cs')"></privacy-statement-cs>
        <privacy-statement-nl ng-if="vm.privacyStatementLanguageService.isCurrent('nl')"></privacy-statement-nl>
        <privacy-statement-ru ng-if="vm.privacyStatementLanguageService.isCurrent('ru')"></privacy-statement-ru>
        <privacy-statement-pl ng-if="vm.privacyStatementLanguageService.isCurrent('pl')"></privacy-statement-pl>
        <privacy-statement-ro ng-if="vm.privacyStatementLanguageService.isCurrent('ro')"></privacy-statement-ro>
        <privacy-statement-sv ng-if="vm.privacyStatementLanguageService.isCurrent('sv')"></privacy-statement-sv>
        <privacy-statement-ca ng-if="vm.privacyStatementLanguageService.isCurrent('ca')"></privacy-statement-ca>
        <privacy-statement-fi ng-if="vm.privacyStatementLanguageService.isCurrent('fi')"></privacy-statement-fi>
        <privacy-statement-el ng-if="vm.privacyStatementLanguageService.isCurrent('el')"></privacy-statement-el>
        <privacy-statement-hu ng-if="vm.privacyStatementLanguageService.isCurrent('hu')"></privacy-statement-hu>
        <privacy-statement-no ng-if="vm.privacyStatementLanguageService.isCurrent('no')"></privacy-statement-no>
        <privacy-statement-tr ng-if="vm.privacyStatementLanguageService.isCurrent('tr')"></privacy-statement-tr>
      </div>
    </div>
    `,
  controllerAs: "vm",
  controller: class privacyStatementCtrl {
    /* @ngInject*/
    constructor(appSettings, privacyStatementLanguageService, commonService) {
      this.appSettings = appSettings;
      this.privacyStatementLanguageService = privacyStatementLanguageService;
      this.commonService = commonService;
    }

    $onInit() {
      this.screenSize = this.commonService.getScreenSize();
      this.display = true;
      setTimeout(async () => {
        angular.element("#privacy-statement-scroll").xrxscrollable({
          scroll: true,
          height: this.screenSize == 7 ? 390 : 504,
          width: this.screenSize == 7 ? 788 : 1006,
        });
      });
    }

    onClose() {
      angular.element("#privacy-statement-scroll").xrxscrollable("destroy");
      this.onOk();
    }
  },
  bindings: {
    onOk: "&",
  },
};

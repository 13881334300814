/* --------------------------------------------------------------------------------------
   print.service.js
   Copyright © 2021 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class printService {
  /*@ngInject*/
  constructor(appSettings, sessionDataService, apiClientService) {
    this.appSettings = appSettings;
    this.sessionDataService = sessionDataService;
    this.apiClientService = apiClientService;
  }

  async SubmitPrint(sessionId, fileName, options) {
    const blobUrl = this.apiClientService.apiUrl + "/api/v1/print/" + sessionId;

    const output = this.xrxPrintOutput(
      options.staple,
      "None",
      "None",
      "Auto",
      "Collated",
      options.copies,
      options.sides,
      "Automatic"
    );
    const input = "";
    const jobProcessing = this.xrxPrintJobProcessing(input, output);

    const jobDescription = this.xrxPrintJobDescription(
      fileName,
      this.appSettings.appName
    );

    const printJobTicket = this.xrxPrintJobTicket(
      jobDescription,
      jobProcessing
    );

    const printInitiatePrintJobURLResult = await this.printInitiatePrintJobURL(
      blobUrl,
      printJobTicket
    );

    return xrxPrintParseInitiatePrintJobURL(
      printInitiatePrintJobURLResult.response
    );
  }

  async SubmitFakePrintJob() {
    const blobUrl = this.appSettings.warmupPdfUrl;

    const output = this.xrxPrintOutput(
      "None",
      "None",
      "None",
      "Auto",
      "Collated",
      1,
      "OneSided",
      "Automatic"
    );
    const input = "";
    const jobProcessing = this.xrxPrintJobProcessing(input, output);

    const jobDescription = this.xrxPrintJobDescription(
      "warmup.pdf",
      this.appSettings.appName
    );

    const printJobTicket = this.xrxPrintJobTicket(
      jobDescription,
      jobProcessing
    );

    const printInitiatePrintJobURLResult = await this.printInitiatePrintJobURL(
      blobUrl,
      printJobTicket
    );

    return xrxPrintParseInitiatePrintJobURL(
      printInitiatePrintJobURLResult.response
    );
  }

  printInitiatePrintJobURL(blobUrl, printJobTicket) {
    return new Promise((resolve, reject) => {
      xrxPrintInitiatePrintJobURL(
        this.sessionDataService.deviceUrl,
        blobUrl,
        "",
        "",
        printJobTicket,
        (env, response) => resolve({ env, response }),
        (request, response, status) => reject({ request, response, status }) // TODO: handle error
      );
    });
  }
  xrxPrintOutput(
    staple,
    punch,
    fold,
    color,
    collate,
    copies,
    sides,
    inputTray
  ) {
    const finishingTag = xrxCreateTag(
      "Finishings",
      "",
      xrxCreateTag("StapleFinishing", "", staple) +
        xrxCreateTag("PunchFinishing", "", punch) +
        xrxCreateTag("FoldFinishing", "", fold)
    );
    const colorEffectsTag = xrxCreateTag("ColorEffectsType", "", color);
    const collationTag = xrxCreateTag("SheetCollate", "", collate);
    const copiesTag = xrxCreateTag("Copies", "", copies);
    const sidesTag = xrxCreateTag("Sides", "", sides);
    const trayTag = xrxCreateTag(
      "InputTraysCol",
      "",
      xrxCreateTag("InputTrayName", "", inputTray)
    );

    return xrxCreateTag(
      "Output",
      "",
      finishingTag +
        colorEffectsTag +
        collationTag +
        copiesTag +
        sidesTag +
        trayTag
    );
  }
  xrxPrintJobTicket(jobDescription, jobProcessing) {
    const ticketStr =
      '<?xml version="1.0" encoding="utf-8"?>' +
      xrxCreateTag(
        "PrintJobTicket",
        'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1"',
        jobDescription + jobProcessing
      );
    return this.xrxEscape(ticketStr);
  }
  xrxPrintJobDescription(jobName, jobOriginating) {
    return xrxCreateTag(
      "JobDescription",
      "",
      xrxCreateTag("JobName", "", jobName) +
        xrxCreateTag("JobOriginatingUserName", "", jobOriginating)
    );
  }
  xrxPrintJobProcessing(input, output) {
    return xrxCreateTag("JobProcessing", "", input + output);
  }
  xrxEscape(text) {
    text = unescape(text);
    text = xrxReplaceChars(text, "<", "&lt;");
    text = xrxReplaceChars(text, ">", "&gt;");
    return text;
  }
}
